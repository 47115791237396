import ApiService from "@/core/services/access.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
// const accessUrl = "http://access.proleanfinancials.biz/api";

const userService = {
  getUserByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(`ProleanUser/GetUser/${key}/reference`);
    }
  },
  // getUsers(fetchModel) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     return ApiService.get(
  //       `${accessUrl}/ProleanUser/GetUsers/pageNumber/${fetchModel.pageIndex}/pageSize/${fetchModel.pageSize}`
  //     );
  //   }
  // },

  getUsers(fetchModel) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      let refer = fetchModel.searchTerm;
      if (refer != null && refer != undefined && refer.trim() != "") {
        return ApiService.get(
          `ProleanUser/GetUsers/${refer.trim()}/pageNumber/${
            fetchModel.pageIndex
          }/pageSize/${fetchModel.pageSize}`
        );
      } else {
        return ApiService.get(
          `ProleanUser/GetUsers/pageNumber/${fetchModel.pageIndex}/pageSize/${fetchModel.pageSize}`
        );
      }
    }
  },

  getUnderwriterUsers(fetchModel) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      let refer = fetchModel.searchTerm;
      if (refer != null && refer != undefined && refer.trim() != "") {
        return ApiService.get(
          `ProleanUser/GetUnderwriterUsers/${refer.trim()}/pageNumber/${
            fetchModel.pageIndex
          }/pageSize/${fetchModel.pageSize}`
        );
      } else {
        return ApiService.get(
          `ProleanUser/GetUnderwriterUsers/pageNumber/${fetchModel.pageIndex}/pageSize/${fetchModel.pageSize}`
        );
      }
    }
  },
};

export default userService;

<template>
  <main class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div class="d-flex justify-content-end align-items-center">
          <button
            type="button"
            class="mx-3 button page__body__header__button button--grey text--capital"
            v-if="!userIsNotAuthorised || !userCanCreateQueues"
          >
            <span
              class="d-inline-flex text--500 mr-2"
              data-toggle="modal"
              data-target="#createNewQueue"
              @click="clearForm"
              >create new queue</span
            >
            <ion-icon name="add-outline" class="text--medium"></ion-icon>
          </button>
          <button
            type="button"
            class="mx-3 button page__body__header__button button--grey text--capital"
            v-if="!isSaving && canDelete && selectedQueues.length > 0"
            @click="preOpenBulkDelete()"
            data-toggle="modal"
            data-target="#deleteModal"
          >
            <span class="d-inline-flex text--500 mr-2" data-modal="#deleteModal"
              >Bulk Delete</span
            >
            <ion-icon name="trash-outline" class="text--medium"></ion-icon>
          </button>
          <button
            type="button"
            v-if="!isSaving && canActivate && selectedQueues.length > 0"
            class="mx-3 button page__body__header__button button--grey text--capital"
            @click="setMultiSwitchActivationParams('ACTIVATE')"
            data-toggle="modal"
            data-target="#multiSwitchActivationModal"
          >
            <span class="d-inline-flex text--500 mr-2">Bulk Activate</span>
          </button>
          <button
            type="button"
            v-if="!isSaving && canDeactivate && selectedQueues.length > 0"
            class="mx-3 button page__body__header__button button--grey text--capital"
            @click="setMultiSwitchActivationParams('DEACTIVATE')"
            data-toggle="modal"
            data-target="#multiSwitchActivationModal"
          >
            <span class="d-inline-flex text--500 mr-2">Bulk Deactivate</span>
          </button>
          <label
            class="d-block has-checkbox page__body__header__label text--black text--600 text--regular text--capital mx-3 banner"
            v-if="!userIsNotAuthorised"
            >Show Deactivated
            <input
              type="checkbox"
              class="form-"
              v-model="showDeactivated"
              @click="switchShowDeactivated()"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th style="width: 2%"></th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 55%"
            >
              name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              status
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
            >
              action
            </th>
          </tr>
        </thead>
        <tbody v-if="userIsNotAuthorised">
          <tr>
            <td colspan="12">
              <div
                class="d-flex flex-column align-items-center justify-content-center p5"
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >{{ errorResponse }}</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="queue in allUnderwriting" v-bind:key="queue.queueKey">
            <td>
              <div class="form__item-bulk">
                <label
                  :for="[`bulkDeactivate-${queue.queueKey}`]"
                  class="d-block has-checkbox text--black text--500 text--regular"
                >
                  <input
                    type="checkbox"
                    :id="[`bulkDeactivate-${queue.queueKey}`]"
                    class="form-"
                    :value="queue.queueName"
                    v-model="queue.selected"
                    @click="toggleSelected(queue)"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <!-- <input
                type="checkbox"
                class="form-"
                v-model="queue.selected"
                @click="toggleSelected(queue)"
              />
              <span class="checkmark"></span> -->
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                queue.queueName
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital"
                >{{ queue.queueStatus }}
              </span>
            </td>
            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#editQueue"
                    @click="editForm(queue.queueKey)"
                    v-if="userCanEditQueues"
                    >edit</a
                  >
                  <a
                    v-if="
                      queue.queueStatus.toLowerCase() == 'inactive' &&
                      userCanActivateDeactivateQueues
                    "
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#switchActivationModal"
                    @click="
                      setSwitchActivationParams(queue.queueKey, 'ACTIVATE')
                    "
                    >activate</a
                  >
                  <a
                    v-if="
                      queue.queueStatus.toLowerCase() == 'active' &&
                      userCanActivateDeactivateQueues
                    "
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#switchActivationModal"
                    @click="
                      setSwitchActivationParams(queue.queueKey, 'DEACTIVATE')
                    "
                    >deactivate</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#deleteModal"
                    @click="setDeleteQueueId(queue)"
                    v-if="userCanDeleteQueues"
                    >delete</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination" v-if="!userIsNotAuthorised">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <!-- <span
              class="d-inline-block text--primary"
              v-if="paging.totalCount == 0"
              >0</span
            > -->
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="size"
                  class="select select--lg select--page"
                  @change="getUnderwritings()"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="size"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              !paging.hasPreviousPage
                ? paging.pageIndex
                : paging.pageSize * (paging.pageIndex - 1) + 1
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              paging.totalCount
            }}</span>
            <!-- of
            <span>{{ paging.totalCount }}</span> -->
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
          >
            <button
              class="button button--sm text--regular"
              v-if="paging.hasPreviousPage"
              @click="stepPage('previous')"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <!-- <button
                class="button text--regular button--sm ml-2 button--primary text--white"
              >
                1
              </button>
              <button class="button text--regular button--sm ml-2">2</button>
              <button class="button text--regular button--sm ml-2">3</button> -->
            <button
              class="button text--regular button--sm ml-2"
              v-for="num in paging.totalPages"
              :key="num"
              :class="{
                'button--primary text--white': paging.pageIndex === num,
              }"
              :disabled="paging.pageIndex === num"
              @click="changePage(num)"
            >
              {{ num }}
            </button>

            <button
              class="button text--regular button--sm ml-2"
              v-if="paging.hasNextPage"
              @click="stepPage('next')"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="createNewQueue"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Creating New Queue Management
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Name"
                    v-model="form.queueName"
                    v-bind:class="{
                      'input-required': formErr.queueName,
                    }"
                  />
                </div>
              </div>
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Loan Product</label>
                  <!-- <select
                    class="select select--lg"
                    v-model="form.linkedEntityKey"
                  >
                    <option value="null" disabled selected>
                      Select loan product
                    </option>
                    <option
                      v-for="item in loanProducts"
                      v-bind:key="item.loanProductKey"
                      v-bind:value="item.loanProductKey"
                    >
                      {{ item.productName }}
                    </option>
                  </select> -->
                  <multiselect
                    v-model="allLoanProducts"
                    placeholder="Search or add a Loan Product"
                    label="productName"
                    track-by="loanProductKey"
                    :options="loanProducts"
                    :multiple="true"
                    :taggable="true"
                    @input="updateLoanProducts"
                  >
                    <span slot="noResult">Oops! No result found.</span>
                  </multiselect>
                </div>
              </div>

              <div class="form__item">
                <label
                  for="forAllBranches"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >For all branches
                  <input
                    type="checkbox"
                    id="forAllBranches"
                    class="form-"
                    v-model="form.forAllBranches"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <!-- <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label"
                    >For all branches
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.forAllBranches"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div> -->

              <!-- <div class="form__item">
                <label
                  for="hasSecondaryApproval"
                  class="
                    d-block
                    has-checkbox
                    text--black text--500 text--regular
                  "
                  >Has Secondary Approval
                  <input
                    type="checkbox"
                    id="hasSecondaryApproval"
                    class="form-"
                    v-model="form.hasSecondaryApproval"
                  />
                  <span class="checkmark"></span>
                </label>
              </div> -->
              <!-- <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label"
                    >Has Secondary Approval
                    <input
                      type="checkbox"
                      class="form-"
                      v-model="form.hasSecondaryApproval"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div> -->

              <div class="modal__accordion" v-if="!form.forAllBranches">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#queueBranch"
                  aria-expanded="false"
                  aria-controls="queueBranch"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Branches
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="queueBranch" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label">Select Branches</label>
                      <select
                        class="select select--lg"
                        @change="addToBranchList()"
                        v-model="form.selectedBranch"
                      >
                        <option value="" disabled selected>
                          Select any branch
                        </option>
                        <option
                          v-for="branch in allBranches"
                          v-bind:key="branch.branchKey"
                          :value="branch.branchKey"
                        >
                          {{ branch.branchName }}
                        </option>
                      </select>
                    </div>
                    <div class="form__item">
                      <label class="form__label">Selected Columns</label>
                      <div class="form__item_dottedbg px-5 py-3">
                        <div
                          v-for="branch in form.branches"
                          v-bind:key="branch.branchKey"
                          class="d-flex my-3 align-items-center justify-content-between"
                        >
                          <p
                            class="text--regular text--black form__item_text3 text--capital"
                          >
                            {{ branch.branchName }}
                          </p>
                          <button
                            type="button"
                            @click="removeFromBranchList(branch.branchKey)"
                            class="mx-2 button button--sm button--cancel text--capital"
                          >
                            <span class="d-inline-flex mr-2">delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#qParams"
                  aria-expanded="false"
                  aria-controls="qParams"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Queue Parameters
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="qParams" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <!-- <div class="form__item">
                      <div class="p-5 border-grey border-radius">
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Where</label>
                              <select class="select select--lg">
                                <option value="" disabled
                                  >Select Where</option
                                >
                                <option value="" selected
                                  >Loan Balance</option
                                >
                                <option value="">Account Balance</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">More than</label>
                              <select class="select select--lg">
                                <option value="" disabled selected
                                  >Select Value</option
                                >
                                <option value="">Option 1</option>
                                <option value="">Option 2</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          class="row align-items-end justify-content-between"
                        >
                          <div class="col-6">
                            <div class="form__item mb-0">
                              <label class="form__label">Enter Amount</label>
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter Amount"
                              />
                            </div>
                          </div>
                          <div class="px-4">
                            <button
                              type="button"
                              class="button button--cancel text--capital"
                            >
                              <span class="d-inline-flex">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div
                      class="form__item"
                      v-for="(param, index) in form.parameters"
                      :key="'param' + index"
                    >
                      <div class="p-5 border-grey border-radius">
                        <div class="row">
                          <span
                            v-if="formErr.parameters[index]"
                            class="d-inline-block text--regular text--danger text--capital text-300"
                          >
                            Check that minimum value is not greater than Maximum
                            value
                          </span>
                          <div class="col-6">
                            <div class="form__item mb-0">
                              <label class="form__label"
                                >Enter Minimum Amount</label
                              >
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter Amount"
                                :value="param.formattedMinValue"
                                @input="numberIsValid(param.formattedMinValue)"
                                @blur="validateFormatedMinValue($event, index)"
                                v-bind:class="{
                                  'input-required': formErr.parameters[index],
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item mb-0">
                              <label class="form__label"
                                >Enter Maximum Amount</label
                              >
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter Amount"
                                :value="param.formattedMaxValue"
                                @input="numberIsValid(param.formattedMaxValue)"
                                @blur="validateFormatedMaxValue($event, index)"
                                v-bind:class="{
                                  'input-required': formErr.parameters[index],
                                }"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="row align-items-end justify-content-between"
                        >
                          <div class="col-6"></div>
                          <div class="px-4">
                            <button
                              type="button"
                              @click="removeFromParameterList(index)"
                              class="button button--cancel text--capital"
                            >
                              <span class="d-inline-flex">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8"></div>
                      <div class="px-4">
                        <button
                          type="button"
                          @click="addToParameterList()"
                          class="button text--capital button--grey"
                        >
                          <span class="d-inline-flex">Add Parameter</span>
                        </button>
                      </div>
                    </div>
                    <!-- <div class="form__item">
                      <div class="p-5 border-grey border-radius">
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">And </label>
                              <select
                                class="select select--lg"
                                onchange="addBranch(this)"
                              >
                                <option value="" disabled selected
                                  >Select branch Name</option
                                >
                                <option value="Head Office Lagos"
                                  >Head Office Lagos</option
                                >
                                <option value="Abuja">Abuja</option>
                                <option value="Ibadan">Ibadan</option>
                                <option value="Marina">Marina</option>
                                <option value="Ikorodu">Ikorodu</option>
                                <option value="Ikeja">Ikeja</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Equals</label>
                              <select class="select select--lg">
                                <option value="" disabled selected
                                  >Select Value</option
                                >
                                <option value="">Option 1</option>
                                <option value="">Option 2</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          class="form__item grey-bg border-0"
                          id="branch-area"
                        ></div>
                        <div class="text--right">
                          <button
                            type="button"
                            class="button button--cancel text--capital"
                          >
                            <span class="d-inline-flex">delete</span>
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Queue status</label>
                  <select class="select select--lg" v-model="form.queueStatus">
                    <option value="" disabled selected></option>
                    <option
                      v-for="stat in queueStatuses"
                      v-bind:key="stat"
                      :value="stat"
                    >
                      {{ stat }}
                    </option>
                  </select>
                </div>
              </div>

              <ul id="accordion" class="mb5 pb5">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#assignWriter"
                    aria-expanded="false"
                    aria-controls="assignWriter"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Assign Underwriters
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="assignWriter"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div
                      v-for="(level, index) in form.underwriters"
                      :key="index"
                      class=""
                    >
                      <div class="pt-5 modal__accordion--toggle">
                        <h5
                          class="w-100 d-flex align-items-center justify-content-between mb-3"
                        >
                          Level {{ index + 1 }} Approval
                        </h5>

                        <div class="col-6 mt-3">
                          <div class="form__item mb-0">
                            <label class="form__label"
                              >Approval level name</label
                            >
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              placeholder="Enter Approval name"
                              v-model="level.approvalLevelName"
                            />
                          </div>
                        </div>

                        <div class="col-6 mt-5 mb-5">
                          <!-- <div class="form__item mb-0">
                            <label
                              class="d-block has-checkbox page__body__header__label text--black text--600 text--regular text--capital mx-3 banner"
                            >
                              User can send offer letter
                              <input
                                type="checkbox"
                                class="form-"
                                v-model="level.hasOfferLetter"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div> -->
                        </div>

                        <SearchSelectInput
                          :label="'Select underwriter'"
                          :err="false"
                          :formIsCleared="formIsCleared"
                          @change="addToApprovalUserList(index, $event)"
                        ></SearchSelectInput>

                        <div class="form__item">
                          <label class="form__label">Selected Columns</label>
                          <div class="form__item_dottedbg px-5 py-3">
                            <div
                              class="d-flex my-3 align-items-center justify-content-between"
                              v-for="user in level.underWriterUserObjects"
                              v-bind:key="user.userKey"
                            >
                              <p
                                class="text--regular text--black form__item_text3 text--capital"
                              >
                                {{ user.userFirstName }} &nbsp;
                                {{ user.userLastName }}
                              </p>
                              <button
                                type="button"
                                @click="
                                  removeFromApprovalUserList(
                                    index,
                                    user.userKey
                                  )
                                "
                                class="mx-2 button button--sm button--cancel text--capital"
                              >
                                <span class="d-inline-flex mr-2">delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="!isApprovalLevelComplete">
                      <div class="col-8"></div>
                      <div class="px-4">
                        <button
                          type="button"
                          @click="addToUnderwriteLevels"
                          class="button text--capital button--grey"
                        >
                          <span class="d-inline-flex"
                            >Add New Approval level</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="modal__accordion" v-if="form.hasSecondaryApproval">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#secondaryApprove"
                    aria-expanded="false"
                    aria-controls="secondaryApprove"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      Secondary Approval
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="secondaryApprove"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <SearchSelectInput
                        :label="'Select Secondary approver'"
                        :err="false"
                        :formIsCleared="formIsCleared"
                        v-model="form.selectedSecondaryUser"
                        @change="addToSecondaryUserList($event)"
                      >
                      </SearchSelectInput>
                      <div class="form__item">
                        <label class="form__label">Selected Columns</label>
                        <div class="form__item_dottedbg px-5 py-3">
                          <div
                            class="
                              d-flex
                              my-3
                              align-items-center
                              justify-content-between
                            "
                            v-for="user in form.secondaryApproverUserObjects"
                            v-bind:key="user.userKey"
                          >
                            <p
                              class="
                                text--regular text--black
                                form__item_text3
                                text--capital
                              "
                            >
                              {{ user.userFirstName }} &nbsp;
                              {{ user.userLastName }}
                            </p>
                            <button
                              type="button"
                              @click="removeFromSecondaryUserList(user.userKey)"
                              class="
                                mx-2
                                button button--sm button--cancel
                                text--capital
                              "
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="row">
                  <div class="col-8"></div>
                  <div class="px-4">
                    <button
                      type="button"
                      @click="addToUnderwriteLevels"
                      class="button text--capital button--grey"
                    >
                      <span class="d-inline-flex">Add New Approval level</span>
                    </button>
                  </div>
                </div> -->
              </ul>
              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Create this Queue
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editQueue"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="editFormCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Editing Queue</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEditForm">
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Name"
                    v-model="form.queueName"
                    v-bind:class="{
                      'input-required': formErr.queueName,
                    }"
                  />
                </div>
              </div>
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Loan Product</label>
                  <multiselect
                    v-model="allLoanProducts"
                    placeholder="Search or add a Loan Product"
                    label="productName"
                    track-by="loanProductKey"
                    :options="loanProducts"
                    :multiple="true"
                    :taggable="true"
                    @input="updateLoanProducts"
                  >
                    <span slot="noResult">Oops! No result found.</span>
                  </multiselect>
                  <!-- <select
                    :disabled="true"
                    class="select select--lg"
                    v-model="form.linkedEntityKey"
                  >
                    <option value="null" disabled selected>
                      Select loan product
                    </option>
                    <option
                      v-for="item in loanProducts"
                      v-bind:key="item.loanProductKey"
                      v-bind:value="item.loanProductKey"
                    >
                      {{ item.productName }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="form__item">
                <label
                  for="forAllBranches"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >For all branches
                  <input
                    type="checkbox"
                    id="forAllBranches"
                    class="form-"
                    v-model="form.forAllBranches"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <!-- <div class="form__item">
                <label
                  for="hasSecondaryApproval"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Has Secondary Approval
                  <input
                    type="checkbox"
                    id="hasSecondaryApproval"
                    class="form-"
                    v-model="form.hasSecondaryApproval"
                  />
                  <span class="checkmark"></span>
                </label>
              </div> -->

              <ul id="accordion" class="mb5 pb5">
                <div class="modal__accordion" v-if="!form.forAllBranches">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#queueBranch"
                    aria-expanded="false"
                    aria-controls="queueBranch"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Branches
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="queueBranch"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <label class="form__label">Select Branches</label>
                        <select
                          class="select select--lg"
                          @change="addToBranchList()"
                          v-model="form.selectedBranch"
                        >
                          <option value="" disabled selected>
                            Select any branch
                          </option>
                          <option
                            v-for="branch in allBranches"
                            v-bind:key="branch.branchKey"
                            :value="branch.branchKey"
                          >
                            {{ branch.branchName }}
                          </option>
                        </select>
                      </div>
                      <div class="form__item">
                        <label class="form__label">Selected Columns</label>
                        <div class="form__item_dottedbg px-5 py-3">
                          <div
                            v-for="branch in form.branches"
                            v-bind:key="branch.branchKey"
                            class="d-flex my-3 align-items-center justify-content-between"
                          >
                            <p
                              class="text--regular text--black form__item_text3 text--capital"
                            >
                              {{ branch.branchName }}
                            </p>
                            <button
                              type="button"
                              @click="removeFromBranchList(branch.branchKey)"
                              class="mx-2 button button--sm button--cancel text--capital"
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#qParams"
                    aria-expanded="false"
                    aria-controls="qParams"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Queue Parameters
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="qParams" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div
                        class="form__item"
                        v-for="(param, index) in form.parameters"
                        :key="'param' + index"
                      >
                        <div class="p-5 border-grey border-radius">
                          <div class="row">
                            <span
                              v-if="formErr.parameters[index]"
                              class="d-inline-block text--regular text--danger text--capital text-300"
                            >
                              Check that minimum value is not greater than
                              Maximum value
                            </span>
                            <div class="col-6">
                              <div class="form__item mb-0">
                                <label class="form__label"
                                  >Enter Minimum Amount</label
                                >
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Amount"
                                  :value="inputCurrencyFormat(param.minValue)"
                                  @input="
                                    numberIsValid(param.formattedMinValue)
                                  "
                                  @blur="
                                    validateFormatedMinValue($event, index)
                                  "
                                  v-bind:class="{
                                    'input-required': formErr.parameters[index],
                                  }"
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form__item mb-0">
                                <label class="form__label"
                                  >Enter Maximum Amount</label
                                >
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Amount"
                                  :value="inputCurrencyFormat(param.maxValue)"
                                  @input="
                                    numberIsValid(param.formattedMaxValue)
                                  "
                                  @blur="
                                    validateFormatedMaxValue($event, index)
                                  "
                                  v-bind:class="{
                                    'input-required': formErr.parameters[index],
                                  }"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="row align-items-end justify-content-between"
                          >
                            <div class="col-6"></div>
                            <div class="px-4">
                              <button
                                type="button"
                                @click="removeFromParameterList(index)"
                                class="button button--cancel text--capital"
                              >
                                <span class="d-inline-flex">delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8"></div>
                        <div class="px-4">
                          <button
                            type="button"
                            @click="addToParameterList()"
                            class="button text--capital"
                          >
                            <span class="d-inline-flex">Add Parameter</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#assignWriter"
                    aria-expanded="false"
                    aria-controls="assignWriter"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Assign Under writer
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="assignWriter"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div
                      v-for="(level, index) in form.underwriterss"
                      :key="index"
                      class="pt-5"
                    >
                      <div class="pt-5 modal__accordion--toggle">
                        <h5
                          class="w-100 d-flex align-items-center justify-content-between mb-3"
                        >
                          Level {{ index + 1 }} Approval
                        </h5>

                        <div class="col-6 mt-3">
                          <div class="form__item mb-0">
                            <label class="form__label"
                              >Approval level name</label
                            >
                            <input
                              type="text"
                              class="input form__input form__input--lg"
                              placeholder="Enter Approval name"
                              v-model="level.approvalLevelName"
                            />
                          </div>
                        </div>

                        <div class="col-6 mt-5 mb-5">
                          <!-- <div class="form__item mb-0">
                            <label
                              class="d-block has-checkbox page__body__header__label text--black text--600 text--regular text--capital mx-3 banner"
                            >
                              User can send offer letter
                              <input
                                type="checkbox"
                                class="form-"
                                v-model="level.hasOfferLetter"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div> -->
                        </div>

                        <SearchSelectInput
                          :label="'Select underwriter'"
                          :err="false"
                          :formIsCleared="formIsCleared"
                          @change="addToEditApprovalUserList(index, $event)"
                        ></SearchSelectInput>

                        <div class="form__item">
                          <label class="form__label">Selected Columns</label>
                          <div class="form__item_dottedbg px-5 py-3">
                            <div
                              class="d-flex my-3 align-items-center justify-content-between"
                              v-for="(user, userIndex) in level.users"
                              :key="userIndex"
                            >
                              <p
                                class="text--regular text--black form__item_text3 text--capital"
                              >
                                {{
                                  user.fullName
                                    ? user.fullName
                                    : getFullName(user)
                                }}
                                <!-- &nbsp;
                                {{ user.userLastName }} -->
                              </p>
                              <button
                                type="button"
                                @click="
                                  removeFromEditApprovalUserList(
                                    index,
                                    user.userKey
                                  )
                                "
                                class="mx-2 button button--sm button--cancel text--capital"
                              >
                                <span class="d-inline-flex mr-2">delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="!isEditApprovalLevelComplete">
                      <div class="col-8"></div>
                      <div class="px-4">
                        <button
                          type="button"
                          @click="addToUnderwriteLevels"
                          class="button text--capital button--grey"
                        >
                          <span class="d-inline-flex"
                            >Add New Approval level</span
                          >
                        </button>
                      </div>
                    </div>
                    <!-- <div class="pt-5">
                      <SearchSelectInput
                        :label="'Select underwriter'"
                        :err="false"
                        v-model="form.selectedUser"
                        @change="addToUserList($event)"
                      ></SearchSelectInput>
                      <div class="form__item">
                        <label class="form__label">Selected Columns</label>
                        <div class="form__item_dottedbg px-5 py-3">
                          <div
                            class="d-flex my-3 align-items-center justify-content-between"
                            v-for="user in form.underWriterUserObjects"
                            v-bind:key="user.userKey"
                          >
                            <p
                              class="text--regular text--black form__item_text3 text--capital"
                            >
                              {{ user.userFirstName }} &nbsp;
                              {{ user.userLastName }}
                            </p>
                            <button
                              type="button"
                              @click="removeFromUserList(user.userKey)"
                              class="mx-2 button button--sm button--cancel text--capital"
                            >
                              <span class="d-inline-flex mr-2">delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </ul>
              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Update this Queue
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="switchActivationModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="modal"
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Under writing Queue
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to {{ switchActivationDisplay }} this
                Underwriting Queue
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  id="activationCloseButton"
                  data-dismiss="modal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                >
                  cancel
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isSaving"
                  @click="switchActivationFunc()"
                  class="button button--lg modal__confirm__button ml-2 save-changes form__button"
                >
                  {{ switchActivationDisplay }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="multiSwitchActivationModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="modal"
                id="multiActivationCloseButton"
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Under writing Queue
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to {{ multiSwitchActivationDisplay }} the
                following Underwriting Queue(s)
              </h5>
              <p class="form__label"></p>
              <ul>
                <li
                  v-for="queue in selectedQueues"
                  :key="queue.queueKey + 'multiAct'"
                >
                  {{ queue.queueName }}
                </li>
              </ul>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="modal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  v-if="!isSaving"
                >
                  cancel
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isSaving"
                  @click="changeSelectedStatus()"
                  class="button button--lg modal__confirm__button ml-2 save-changes"
                >
                  {{ multiSwitchActivationDisplay }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="modal"
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Under writing Queue
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to delete the following underwriting
                queue(s)
              </h5>
              <ul>
                <li
                  v-for="queue in queuesToDelete"
                  v-bind:key="queue.queueKey + 'delete'"
                >
                  {{ queue.queueName }}
                </li>
              </ul>
              <p class="form__label">
                When you delete a queue, all queue settings are lost and loan
                applications not yet assigned will be returned back to general
                pool.
              </p>
              <div class="d-flex mt-5">
                <button
                  id="deleteCloseButton"
                  data-dismiss="modal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                >
                  cancel
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isSaving"
                  @click="deleteQueue()"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                >
                  delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import branchesService from "@/core/services/organization-service/branches.service";
import otherService from "@/core/services/other-service/other.service";
import userService from "@/core/services/other-service/user.service";
import underwritingService from "@/core/services/underwriting/underwriting.service";
import SearchSelectInput from "@/components/Inputs/_base-search-select-input.vue";
import Multiselect from "vue-multiselect";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import currencyMixin from "@/mixins/currencyMixin";

export default Vue.extend({
  name: "underwriting",
  mixins: [currencyMixin],
  components: { SearchSelectInput, Multiselect },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateQueues() {
      const underwriting = this.user.scopes.find(
        (item) => item.scopeName === Role.Underwrting
      );
      const { permissionsList } = underwriting;
      return permissionsList.CanCreateQueues;
    },
    userCanActivateDeactivateQueues() {
      const underwriting = this.user.scopes.find(
        (item) => item.scopeName === Role.Underwrting
      );
      const { permissionsList } = underwriting;
      return permissionsList.CanActivateDeactiveQueues;
    },
    userCanDeleteQueues() {
      const underwriting = this.user.scopes.find(
        (item) => item.scopeName === Role.Underwrting
      );
      const { permissionsList } = underwriting;
      return permissionsList.CanDeleteQueues;
    },
    userCanEditQueues() {
      const underwriting = this.user.scopes.find(
        (item) => item.scopeName === Role.Underwrting
      );
      const { permissionsList } = underwriting;
      return permissionsList.CanEditQueues;
    },
    userIsNotAuthorised() {
      return this.errorCode == 409;
    },
    isApprovalLevelComplete() {
      return this.underwriterLevels.length === 10;
    },
    isEditApprovalLevelComplete() {
      return this.underwriterLevels.length === 10;
    },
  },
  data: function () {
    return {
      trackBy: "loanProductKey",
      content_header: {
        title: "Underwriting Queue Management",
        subTitle:
          "Setup your underwriting queues here to auto-assign loan applications to underwriters.",
      },
      queueStatuses: ["Active", "Inactive"],
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      showDeactivated: false,
      size: 20,
      allUsers: [],
      userFetchModel: {
        pageIndex: 1,
        pageSize: 10,
      },

      allBranches: [],
      loanProducts: [],
      branchFetchModel: {
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
      },
      formIsCleared: false,
      underwriting: null,
      allUnderwriting: [],
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      isDeactivating: false,

      fetchModel: {
        linkedEntityKey: [],
        queueType: "Underwriting",
        queueStatus: "Active",
        parameterLinkOperator: "And",
        pageIndex: 1,
        pageSize: 10,
      },
      allLoanProducts: [],
      form: {
        queueKey: null,
        queueName: null,
        forAllBranches: true,
        hasSecondaryApproval: true,
        linkedEntityKey: [],
        queueStatus: "Active",
        branchKeys: [],
        branches: [],
        underwriters: [],
        underWriterKeys: [],
        underWriterUserObjects: [],
        secondaryApproverKeys: [],
        secondaryApproverUserObjects: [],
        parameters: [],
        underwriterss: [],

        selectedBranch: null,
        selectedUser: null,
        selectedSecondaryUser: null,
      },
      formErr: {
        queueName: false,
        parameters: [],
      },
      underwriterLevels: [],
      toSwitchActivation: null,
      switchActivationDisplay: null, // ACTIVATE DEACTIVATE
      toDelete: null,
      queuesToDelete: [],
      errorCode: "",
      errorResponse: "",
      selectedQueues: [],
      canActivate: false,
      canDeactivate: false,
      canDelete: false,
      multiSwitchActivationDisplay: null,
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
    };
  },
  methods: {
    updateLoanProducts(allLoanProducts) {
      let allLoanProductName = [];
      allLoanProducts.forEach((loanProduct) => {
        allLoanProductName.push(loanProduct.loanProductKey);
      });
      this.form.linkedEntityKey = allLoanProductName;
    },
    getFullName(user) {
      return user.userFirstName + " " + user.userLastName;
    },
    async getBranches() {
      try {
        this.allBranches = [];
        this.isLoading = true;
        const res = await branchesService.get(this.branchFetchModel);
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getUsers() {
      try {
        this.allUsers = [];
        this.isLoading = true;
        const res = await userService.getUsers(this.userFetchModel);
        this.allUsers = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getUnderwritings() {
      try {
        this.allUnderwriting = [];
        this.isLoading = true;
        const model = { ...this.fetchModel, pageSize: this.size };
        const res = await underwritingService.get(model);
        const underwriting = res.data.data.items;

        underwriting.forEach((x) => {
          x.selected = false;
        });
        this.selectedQueues = [];
        this.canActivate = false;
        this.canDeactivate = false;
        this.canDelete = false;

        this.allUnderwriting = underwriting;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.errorCode = e.response.status;
        this.errorResponse = e.response.data;
        const error = e.response.data;
        this.alertError(error);
      }
    },
    async getLoanProducts() {
      try {
        this.isLoading = true;
        const res = await otherService.getAllLoanProducts();
        this.loanProducts = res.data.data;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    getUnderwritingByKey(key) {
      try {
        this.isLoading = true;
        underwritingService.getByKey(key).then((res) => {
          this.editForm = res.data.data;
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editForm(queueKey) {
      this.clearForm();
      try {
        this.isLoading = true;
        underwritingService.getByKey(queueKey).then((res) => {
          const queue = res.data.data;
          queue.branches = queue.queueBranches;
          queue.branchKeys = queue.branches.map((x) => x.branchKey);

          queue.underWriterUserObjects = queue.underwritingUsers;
          queue.underWriterKeys = queue.underWriterUserObjects.map(
            (x) => x.userKey
          );

          queue.secondaryApproverUserObjects = queue.secondaryApproverUsers;
          queue.secondaryApproverKeys = queue.secondaryApproverUserObjects.map(
            (x) => x.userKey
          );

          queue.parameters = queue.queueParamaters;
          this.formErr.parameters = [];
          for (let index = 0; index < queue.parameters.length; index++) {
            this.formErr.parameters.push(false);
          }

          const otherParams = {
            selectedBranch: null,
            selectedUser: null,
            selectedSecondaryUser: null,
          };
          this.form = queue;

          const underwriter = queue.underwriters.map((underwriter) => {
            return {
              ...underwriter,
              underWriterUserObjects: underwriter.users,
            };
          });
          // this.form.writers = underwriter.sort((a, b) => a.orderOfApproval - b.orderOfApproval);
          this.form.underwriters = underwriter.sort(
            (a, b) => a.orderOfApproval - b.orderOfApproval
          );
          this.form.underwriterss = this.form.underwriters;

          this.allLoanProducts = this.loanProducts.filter((product) =>
            this.form.linkedEntityKeys.includes(product.loanProductKey)
          );
          this.updateLoanProducts(this.allLoanProducts);
          Object.assign(this.form, otherParams);

          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.form.underwriters.length === 0) {
        this.alertError("Please assign at least one underwriter");
        return;
      }
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          // this.form.underwriters = this.form.underwriters.map((underwriter) => ({
          //   approvalLevelName: underwriter.approvalLevelName,
          //   approvalLevelValue: underwriter.approvalLevelValue,
          //   approvalLevelUserKey: underwriter.approvalLevelUserKey,
          //   hasOfferLetter: underwriter.hasOfferLetter,
          //   fullName: underwriter.fullName,
          // }));
          await underwritingService.create(this.form);
          this.getUnderwritings();
          this.clearForm();
          document.getElementById("formCloseButton").click();
          this.alertSuccess("Underwriting Queue Created Successfully.");
          this.isSaving = false;
          this.clearForm();
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.form.underwriterss.length === 0) {
        this.alertError("Please assign at least one underwriter");
        return;
      }
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          if (this.form.forAllBranches) {
            this.form.branchKeys = [];
            this.form.branches = [];
          }
          this.form.underwriters = this.form.underwriterss.map(
            (underwriter) => ({
              approvalLevelName: underwriter.approvalLevelName,
              approvalLevelValue: underwriter.approvalLevelValue,
              hasOfferLetter: underwriter.hasOfferLetter,
              users: underwriter.users.map((key) => key.userKey),
            })
          );
          this.form.linkedEntityKeys = this.form.linkedEntityKey;
          await underwritingService.update(this.form);
          this.getUnderwritings();
          this.clearForm();
          document.getElementById("editFormCloseButton").click();
          this.alertSuccess("Underwriting updated.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },
    validateForm() {
      this.clearFormValidation();
      if (
        this.form.queueName &&
        this.form.parameters.reduce(
          (r, elem) => r && !(Number(elem.minValue) > Number(elem.maxValue)),
          true
        )
      ) {
        return true;
      }

      for (const key in this.formErr) {
        if (Object.hasOwnProperty.call(this.formErr, key)) {
          if (key == "parameters") {
            this.form.parameters.forEach((elem, index) => {
              if (Number(elem.minValue) > Number(elem.maxValue)) {
                this.formErr.parameters[index] = true;
              }
            });
          } else {
            if (
              this.form[key] == null ||
              this.form[key] == undefined ||
              this.form[key].trim() == ""
            ) {
              this.formErr[key] = true;
            }
          }
        }
      }
      return false;
    },
    clearForm() {
      this.allLoanProducts = [];
      this.formIsCleared = !this.formIsCleared;
      this.form = {
        queueKey: null,
        queueName: null,
        forAllBranches: true,
        hasSecondaryApproval: true,
        linkedEntityKey: null,
        queueStatus: "Active",
        branchKeys: [],
        branches: [],
        underWriterKeys: [],
        underWriterUserObjects: [],
        secondaryApproverKeys: [],
        secondaryApproverUserObjects: [],
        parameters: [],
        selectedBranch: null,
        selectedUser: null,
        selectedSecondaryUser: null,
        underwriters: [],
        underwriterss: [],
      };
    },
    clearFormValidation() {
      this.formErr.queueName = false;
      for (let i = 0; i < this.formErr.parameters.length; i++) {
        this.formErr.parameters[i] = false;
      }
    },
    setSwitchActivationParams(queueKey, display) {
      this.toSwitchActivation = queueKey;
      this.switchActivationDisplay = display;
    },
    setMultiSwitchActivationParams(display) {
      this.multiSwitchActivationDisplay = display;
    },
    switchActivationFunc: async function () {
      switch (this.switchActivationDisplay) {
        case "ACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: [this.toSwitchActivation],
              status: "Active",
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Activated.");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
            for (let err in e.response.data.errorsList) {
              this.alertError(e.response.data.errorsList[err]);
            }
          }
          break;
        case "DEACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: [this.toSwitchActivation],
              status: "Inactive",
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Deactivated");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
          break;
        default:
          break;
      }
    },
    preOpenBulkDelete() {
      this.queuesToDelete = this.selectedQueues;
    },
    setDeleteQueueId(queue) {
      this.queuesToDelete = [queue];
    },
    deleteQueue: async function () {
      try {
        this.isSaving = true;
        const toDelete = this.queuesToDelete.map((x) => x.queueKey);
        await underwritingService.delete(toDelete);
        this.getUnderwritings();
        document.getElementById("deleteCloseButton").click();
        this.alertSuccess("Deleted.");
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.errorsList[0];
        this.alertError(error);
      }
    },
    switchShowDeactivated() {
      this.showDeactivated = !this.showDeactivated;
      if (this.showDeactivated) {
        this.fetchModel.queueStatus = null;
      } else {
        this.fetchModel.queueStatus = "Active";
      }
      this.getUnderwritings();
    },

    addToBranchList() {
      const branchKey = this.form.selectedBranch;
      if (!this.form.branchKeys.includes(branchKey)) {
        let branch = this.allBranches.find((x) => x.branchKey == branchKey);
        if (branch != null) {
          this.form.branchKeys.push(branchKey);
          this.form.branches.push(branch);
        }
      }
    },
    removeFromBranchList(branchKey) {
      let ind = this.form.branchKeys.findIndex((x) => x == branchKey);
      this.form.branchKeys.splice(ind, 1);

      let ind2 = this.form.branches.findIndex((x) => x.branchKey == branchKey);
      this.form.branches.splice(ind2, 1);
    },

    addToUserList(user) {
      const userKey = user.userKey;
      if (!this.form.underWriterKeys.includes(userKey)) {
        this.form.underWriterKeys.push(userKey);
        this.form.underWriterUserObjects.push(user);
      }
    },
    addToApprovalUserList(index, user) {
      const userKey = user.userKey;
      const level = this.form.underwriters[index];
      if (index === 0) {
        level.approvalLevelValue = "LevelOneApproval";
      } else if (index === 1) {
        level.approvalLevelValue = "LevelTwoApproval";
      } else if (index === 2) {
        level.approvalLevelValue = "LevelThreeApproval";
      } else if (index === 3) {
        level.approvalLevelValue = "LevelFourApproval";
      } else if (index === 4) {
        level.approvalLevelValue = "LevelFiveApproval";
      } else if (index === 5) {
        level.approvalLevelValue = "LevelSixApproval";
      } else if (index === 6) {
        level.approvalLevelValue = "LevelSevenApproval";
      } else if (index === 7) {
        level.approvalLevelValue = "LevelEightApproval";
      } else if (index === 8) {
        level.approvalLevelValue = "LevelNineApproval";
      } else {
        level.approvalLevelValue = "LevelTenApproval";
      }
      if (!level.underWriterKeys.includes(userKey)) {
        level.underWriterKeys.push(userKey);
        level.users.push(userKey);
        level.underWriterUserObjects.push(user);
      }
    },
    addToEditApprovalUserList(index, user) {
      const userKey = user.userKey;
      const level = this.form.underwriterss[index];
      if (index === 0) {
        level.approvalLevelValue = "LevelOneApproval";
      } else if (index === 1) {
        level.approvalLevelValue = "LevelTwoApproval";
      } else if (index === 2) {
        level.approvalLevelValue = "LevelThreeApproval";
      } else if (index === 3) {
        level.approvalLevelValue = "LevelFourApproval";
      } else if (index === 4) {
        level.approvalLevelValue = "LevelFiveApproval";
      } else if (index === 5) {
        level.approvalLevelValue = "LevelSixApproval";
      } else if (index === 6) {
        level.approvalLevelValue = "LevelSevenApproval";
      } else if (index === 7) {
        level.approvalLevelValue = "LevelEightApproval";
      } else if (index === 8) {
        level.approvalLevelValue = "LevelNineApproval";
      } else {
        level.approvalLevelValue = "LevelTenApproval";
      }
      if (!level.users.includes(userKey)) {
        level.users.push(user);
      }
    },
    addToUnderwriteLevels() {
      const levels = {
        approvalLevelName: "",
        approvalLevelValue: "",
        hasOfferLetter: false,
        users: [],
        underWriterKeys: [],
        underWriterUserObjects: [],
      };
      this.form.underwriters.push(levels);
    },
    // addToEditUnderwriteLevels() {
    //   const newLevel = {
    //     approvalLevelName: "",
    //     approvalLevelValue: "",
    //     hasOfferLetter: false,
    //     users: [],
    //     underWriterKeys: [],
    //     underWriterUserObjects: [],
    //   };
    //   this.form.underwriterss.push(newLevel);
    // },
    removeFromApprovalUserList(index, userKey) {
      const level = this.form.underwriters[index];
      let ind = level.underWriterKeys.findIndex((x) => x == userKey);
      level.underWriterKeys.splice(ind, 1);

      let ind2 = level.underWriterUserObjects.findIndex(
        (x) => x.userKey == userKey
      );
      level.underWriterUserObjects.splice(ind2, 1);

      let ind3 = level.users.findIndex((x) => x === userKey);
      level.users.splice(ind3, 1);
    },
    removeFromEditApprovalUserList(index, userKey) {
      const level = this.form.underwriterss[index];
      // let ind = level.underWriterKeys.findIndex((x) => x == userKey);
      // level.underWriterKeys.splice(ind, 1);

      // let ind2 = level.underWriterUserObjects.findIndex(
      //   (x) => x.userKey == userKey
      // );
      // level.underWriterUserObjects.splice(ind2, 1);

      let ind3 = level.users.findIndex((x) => x.userKey === userKey);
      level.users.splice(ind3, 1);
    },
    removeFromUserList(userKey) {
      // const level = this.underwriterLevels[index];
      let ind = this.form.underWriterKeys.findIndex((x) => x == userKey);
      this.form.underWriterKeys.splice(ind, 1);

      let ind2 = this.form.underWriterUserObjects.findIndex(
        (x) => x.userKey == userKey
      );
      this.form.underWriterUserObjects.splice(ind2, 1);
    },

    addToSecondaryUserList(user) {
      const userKey = user.userKey;
      if (!this.form.secondaryApproverKeys.includes(userKey)) {
        this.form.secondaryApproverKeys.push(userKey);
        this.form.secondaryApproverUserObjects.push(user);
      }
    },
    removeFromSecondaryUserList(userKey) {
      let ind = this.form.secondaryApproverKeys.findIndex((x) => x == userKey);
      this.form.secondaryApproverKeys.splice(ind, 1);

      let ind2 = this.form.secondaryApproverUserObjects.findIndex(
        (x) => x.userKey == userKey
      );
      this.form.secondaryApproverUserObjects.splice(ind2, 1);
    },
    // Params
    addToParameterList() {
      this.formErr.parameters.push(false);
      this.form.parameters.push({
        minValue: 0,
        maxValue: 1,
        formattedMinValue: "",
        formattedMaxValue: "",
      });
    },
    removeFromParameterList(index) {
      this.form.parameters.splice(index, 1);
      this.formErr.parameters.splice(index, 1);
    },
    // Selection
    toggleSelected(queue) {
      const index = this.selectedQueues.findIndex(
        (x) => x.queueKey == queue.queueKey
      );
      if (index == -1) {
        this.selectedQueues.push(queue);
      } else {
        this.selectedQueues.splice(index, 1);
      }

      let canActivate = true;
      let canDeactivate = true;
      let canDelete = true;

      this.selectedQueues.forEach((q) => {
        switch (q.queueStatus) {
          case "Active":
            canActivate &&= false;
            canDelete &&= false;
            canDeactivate &&= true;
            break;
          case "Inactive":
            canActivate &&= true;
            canDelete &&= true;
            canDeactivate &&= false;
            break;
          default:
            break;
        }
      });
      this.canActivate = canActivate;
      this.canDeactivate = canDeactivate;
      this.canDelete = canDelete;
    },
    changeSelectedStatus: async function () {
      let keys = this.selectedQueues.map((x) => x.queueKey);
      switch (this.multiSwitchActivationDisplay) {
        case "ACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: keys,
              status: "Active",
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("multiActivationCloseButton").click();
            this.alertSuccess("Activated.");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
            for (let err in e.response.data.errorsList) {
              this.alertError(e.response.data.errorsList[err]);
            }
          }
          break;
        case "DEACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: keys,
              status: "Inactive",
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("multiActivationCloseButton").click();
            this.alertSuccess("Deactivated");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
            for (let err in e.response.data.errorsList) {
              this.alertError(e.response.data.errorsList[err]);
            }
          }
          break;
        default:
          break;
      }
    },
    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description, array = null) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = array;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    // Paging
    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      this.getUnderwritings();
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.paging.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.paging.pageIndex - 1;
      }
      this.getUnderwritings();
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },
    validateFormatedMinValue(amount, index) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.form.parameters[index].minValue = amtPaid;
        this.form.parameters[index].formattedMinValue = amountInputted;
        return;
      }
      if (
        this.form.parameters[index].minValue >
        this.form.parameters[index].maxValue
      ) {
        this.formErr.parameters[index] = true;
      }
      this.form.parameters[index].minValue =
        this.form.parameters[index].minValue;
      this.form.parameters[index].formattedMinValue = "";
    },
    validateFormatedMaxValue(amount, index) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.form.parameters[index].maxValue = amtPaid;
        this.form.parameters[index].formattedMaxValue = amountInputted;
        return;
      }
      if (
        this.form.parameters[index].minValue >
        this.form.parameters[index].maxValue
      ) {
        this.formErr.parameters[index] = true;
      }
      this.form.parameters[index].maxValue =
        this.form.parameters[index].formattedMaxValue;
      this.form.parameters[index].formattedMaxValue = "";
    },
  },
  async created() {
    this.getBranches();
    this.getLoanProducts();
    this.getUnderwritings();
    // this.getUsers();
  },
});
</script>
<style scoped>
.customtable tbody td {
  font-weight: inherit;
  font-size: 1.4rem;
  line-height: 17px;
  color: #1e1f20;
}
.has-checkbox,
.text--lh2 {
  line-height: 2rem;
}
.has-checkbox .checkmark {
  top: 0px;
}
label.banner .checkmark {
  top: 0;
}
.form__item-bulk {
  margin-bottom: 2rem;
}
</style>

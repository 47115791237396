<template>
  <div class="form__item form__item_dottedbg px-5 py-3">
    <label class="form__label" v-if="label">{{ label }}</label>
    <div class="row">
      <div class="col-8">
        <input
          type="text"
          v-model="userFetchModel.searchTerm"
          class="input"
          placeholder="Search"
        />
      </div>
      <div class="col-4">
        <button
          type="button"
          @click="getUsers()"
          class="mx-2 button button--sm button--primary"
        >
          Search
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <select
          class="select"
          :class="{
            'input-required': err || false,
          }"
          :value="value"
          @input="handleInput($event.target.value)"
        >
          <option :value="null" disabled selected>Select any user</option>
          <option
            v-for="user in allUsers"
            :key="'sell' + user.userKey"
            :value="user.userKey"
          >
            {{ user.userFirstName }} {{ user.userLastName }}
          </option>
        </select>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <div v-if="!isLoading" class="pagination">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.hasNextPage
                  ? paging.pageSize * paging.pageIndex
                  : paging.totalCount
              }}</span>
              of
              <span>{{ paging.totalCount }}</span>
            </div>
            <div
              class="pagination__buttons align-items-center justify-content-between"
            >
              <button
                style="display: inline"
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                style="display: inline"
                class="button text--regular button--sm ml-2"
                v-for="num in paging.totalPages"
                :key="num"
                :class="{
                  'button--primary text--white': paging.pageIndex === num,
                }"
                :disabled="paging.pageIndex === num"
                @click="changePage(num)"
              >
                {{ num }}
              </button>

              <button
                style="display: inline"
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div v-if="isLoading" class="spinner-border text-dark" role="status"></div>
  </div>
</template>

<script>
import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import userService from "@/core/services/other-service/user.service";
export default Vue.extend({
  name: "SearchSelectInput",
  inheritAttrs: false,
  props: ["label", "value", "err", "formIsCleared"],
  data: function () {
    return {
      allUsers: [],
      selectedUser: null,
      userFetchModel: {
        pageIndex: 1,
        pageSize: 100,
        searchTerm: null,
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      isLoading: false,
    };
  },
  methods: {
    async getUsers() {
      this.$emit("input", null);
      this.isLoading = true;
      try {
        this.allUsers = [];
        this.isLoading = true;
        const res = await userService.getUnderwriterUsers(this.userFetchModel);
        this.allUsers = res.data.data;
        const paging = res.data;
        paging.hasPreviousPage = paging.hasPrevious;
        paging.hasNextPage = paging.hasNext;
        paging.pageIndex = paging.currentPage;
        this.paging = paging;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
      // this.allUsers = [
      //   {
      //     userKey: "d3ba10d18dc14da78f890e13b4c8e358",
      //     branchKey: "6b4bcdad5ea94ec5906188fa9179562b",
      //     apiConsumerType: "--",
      //     userEmail: "TDJ8W@gmail.com",
      //     userFirstName: "R8CAE",
      //     userLastName: "rcep"
      //   },
      //   {
      //     userKey: "5c0d7a4a188d4ea7986bc5b9f897f32b",
      //     branchKey: "6b4bcdad5ea94ec5906188fa9179562b",
      //     apiConsumerType: "--",
      //     userEmail: "TDJ8W@gmail.com",
      //     userFirstName: "tayyyip",
      //     userLastName: "AJ5O3"
      //   }
      // ];
      // this.isLoading = false;
    },
    handleInput(data) {
      this.$emit("input", data);

      const user = this.allUsers.find((x) => x.userKey == data);
      this.$emit("change", user);
    },
    // Paging
    changePage(pageNum) {
      this.userFetchModel.pageIndex = pageNum;
      this.getUsers();
    },
    stepPage(direction) {
      if (direction == "next") {
        this.userFetchModel.pageIndex = this.paging.pageIndex + 1;
      }
      if (direction == "previous") {
        this.userFetchModel.pageIndex = this.paging.pageIndex - 1;
      }
      this.getUsers();
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
  },
  async created() {
    this.getUsers();
  },
  watch: {
    formIsCleared: function (newValue) {
      if (newValue) return (this.userFetchModel.searchTerm = null);
      return (this.userFetchModel.searchTerm = null);
    },
  },
});
</script>
